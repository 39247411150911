import axios from "axios";

export const getMensajeInicioApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `informaciones?action=get-mensaje-inicio`
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        })
    })
}